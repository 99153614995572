<template>
    <div class="fixed bottom-0 left-0 z-40 block h-16 w-full lg:hidden" :class="mainClasses">
        <nav class="mx-auto grid h-full max-w-lg grid-cols-4 font-medium">
            <NuxtLink to="/" :class="linkClasses">
                <IconHome></IconHome>
                <span :class="spanClasses">Home</span>
            </NuxtLink>
            <NuxtLink to="/courses" :class="linkClasses">
                <IconCourses></IconCourses>
                <span :class="spanClasses">Courses</span>
            </NuxtLink>
            <NuxtLink to="/videos" :class="linkClasses">
                <IconSongs></IconSongs>
                <span :class="spanClasses">Videos</span>
            </NuxtLink>
            <NuxtLink to="/live" :class="linkClasses">
                <IconLive></IconLive>
                <span :class="spanClasses">Live</span>
            </NuxtLink>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'AppFooterMenu',
    computed: {
        linkClasses () {
            if (this.$route.name === 'index') return 'group inline-flex flex-col items-center justify-center px-5 text-zinc-100 hover:bg-zinc-900';
            else {
                return 'group inline-flex flex-col items-center justify-center px-5 text-zinc-100 hover:bg-sky-800';
            }
        },
        spanClasses () {
            return 'text-sm text-grey-100';
        },
        mainClasses () {
            if (this.$route.name === 'index') return 'bg-zinc-950';
            else {
                return 'bg-sky-900';
            }
        },
    },
};
</script>
